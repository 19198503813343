import {customElement, query, property} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkInput} from "@/components/input/input";
import {SbkSelect} from "@/components/select/select";

@customElement('sbk-wst-highschool-suggestion')
export class SbkWstHighSchoolSuggestion extends LitElement {

    @query('#locationOfHighschool')
    formFieldLocationOfHighschool!: SbkInput;

    @query('#nameOfHighschool')
    formFieldNameOfHighschool!: SbkSelect;

    @query('#nameOfHighschool')
    formFieldHighschoolSuggestionsLanguage!: HTMLInputElement;

    @property()
    labelPleaseChoose: string = '';

    @property()
    labelEnterLocation: string = '';

    protected createRenderRoot() {
        return this
    }

    connectedCallback() {
        super.connectedCallback();
        this.formFieldLocationOfHighschool.addEventListener('input-change', (e) => {
            this.handleLocationChange((e as CustomEvent).detail.value);
        });
    }

    async handleLocationChange(input: string) {
        const location = input.trim();
        if (location.length > 2) {
          const host = window.location.origin;
          try {
            const response = await fetch(`${host}/ajax/suggestHighschool?hsearchlang=${this.formFieldHighschoolSuggestionsLanguage.value}&term=${encodeURIComponent(location)}`);
            const data = await response.json();

            const selectOptions = data.map((selection: string) => ({
              value: selection,
              label: selection,
              selected: false,
              disabled: false,
            }));

            selectOptions.unshift({
              value: '',
              label: this.labelPleaseChoose,
              description: '',
              selected: true
            });

            this.formFieldNameOfHighschool.options = selectOptions;
          } catch (error) {
            console.error('Error fetching highschool suggestions:', error);
          }
        } else if (location.length === 0) {
          this.formFieldNameOfHighschool.options = [{
              value: '',
              label: this.labelEnterLocation,
              description: '',
              selected: true,
            }];
        }
    }
}
