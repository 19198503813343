import {customElement} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkRadio} from "@/components/radio/radio";
import {SbkDatepicker} from "@/components/datepicker/datepicker";
import {SbkRadioGroup} from "@/components/radio-group/radio-group";

@customElement('sbk-wst-wages')
export class SbkWstWages extends LitElement {

    protected createRenderRoot() {
        return this
    }

    constructor() {
        super();
        this.initializeWagesButtons();
    }

    connectedCallback() {
        super.connectedCallback();
        // assessmentSheet-employments_0_averageMonthlyWages
        const parentId = this.getParentId();
        const searchIds = [];
        searchIds.push(parentId.replace('averageMonthlyWages', 'employmentFrom'));
        searchIds.push(parentId.replace('averageMonthlyWages', 'employmentTo'));

        searchIds.forEach(identifier => {
            if (identifier !== '') {
                const element: HTMLElement | null = document.querySelector(`#${identifier}`);
                if (element) {
                    element.addEventListener("datepicker-change", () => {
                        this.changeWagesValue(this.getParentId())
                    });
                }
            }
        });
    }

    getParentId(): string {
        const radioGroup: SbkRadioGroup | null = this.querySelector('sbk-radio-group')
        let id = '';
        if (radioGroup) {
            id = radioGroup.id
        }

        return id
    }

    /**
     * Initializes wages selection radio buttons
     */
    initializeWagesButtons() {
        // Call function automatically because of prefilled correct form feature
        const employmentFrom: SbkDatepicker | null = document.querySelector('#employmentFrom')
        if (employmentFrom) {
            this.changeWagesValue(this.getParentId());
        }

        // Call function automatically because of prefilled correct form feature
        const additionalEmploymentsLast12Months: SbkDatepicker | null = document.querySelector('#additionalEmploymentsLast12Months')
        if (additionalEmploymentsLast12Months) {
            const parentId = this.getParentId();
            const targetId = parentId.replace('averageMonthlyWages', 'employmentFrom');
            if (targetId !== '') {
                this.changeWagesValue(parentId);
            }
        }
    }

    changeWagesValue(parentId: string) {
        const idEmploymentFrom = parentId.replace('averageMonthlyWages', 'employmentFrom');
        const employmentFrom: SbkDatepicker | null = document.querySelector(`#${idEmploymentFrom}`)

        const idEmploymentTo = parentId.replace('averageMonthlyWages', 'employmentTo');
        const employmentTo: SbkDatepicker | null = document.querySelector(`#${idEmploymentTo}`)

        if (employmentFrom?.datepickerValue && employmentTo?.datepickerValue) {

            fetch(`/getAssessmentConfiguration?employmentfrom=${
                encodeURIComponent(employmentFrom.datepickerValue)
            }&employmentto=${
                encodeURIComponent(employmentTo.datepickerValue)
            }`)
                .then((response) => {
                    return response.text()
                })
                .then((dataJson) => {
                    const data = JSON.parse(dataJson)
                    if (data.countPeriods === 1) {

                        const averageMonthlyWages2: SbkRadio | null = <SbkRadio>document.getElementById(`${parentId}_2`);
                        if (averageMonthlyWages2) {
                            averageMonthlyWages2.value = "2#" + data.marginalLimit1;
                            averageMonthlyWages2.label = averageMonthlyWages2.label.replace(/<span id='wagesSpan2'>(.*?)<\/span>/, `<span id='wagesSpan2'>${data.marginalLimit1}</span>`)
                        }

                        const averageMonthlyWages3: SbkRadio | null = <SbkRadio>document.getElementById(`${parentId}_3`);
                        if (averageMonthlyWages3) {
                            averageMonthlyWages3.value = "3#" + data.marginalLimit1;
                            averageMonthlyWages3.label = averageMonthlyWages3.label.replace(/<span id='wagesSpan3'>(.*?)<\/span>/, `<span id='wagesSpan3'>${data.marginalLimit1}</span>`)
                        }

                        const averageMonthlyWages4: SbkRadio | null = <SbkRadio>document.getElementById(`${parentId}_4`);
                        if (averageMonthlyWages4) {
                            averageMonthlyWages4.classList.add('hidden');
                        }
                    }

                    if (data.countPeriods === 2) {
                        const averageMonthlyWages2: SbkRadio | null = <SbkRadio>document.getElementById(`${parentId}_2`);
                        if (averageMonthlyWages2) {
                            const min = Math.min(data.marginalLimit1, data.marginalLimit2);
                            averageMonthlyWages2.value = "2#" + min
                            averageMonthlyWages2.label = averageMonthlyWages2.label.replace(/<span id='wagesSpan2'>(.*?)<\/span>/, `<span id='wagesSpan2'>${min}</span>`)
                        }

                        const averageMonthlyWages3: SbkRadio | null = <SbkRadio>document.getElementById(`${parentId}_3`);
                        if (averageMonthlyWages3) {
                            const max = Math.max(data.marginalLimit1, data.marginalLimit2);
                            averageMonthlyWages3.value = "3#" + max;
                            averageMonthlyWages3.label = averageMonthlyWages3.label.replace(/<span id='wagesSpan3'>(.*?)<\/span>/, `<span id='wagesSpan3'>${max}</span>`)
                        }

                        const averageMonthlyWages4: SbkRadio | null = <SbkRadio>document.getElementById(`${parentId}_4`);
                        if (averageMonthlyWages4) {
                            averageMonthlyWages4.classList.remove('hidden');
                            const min = Math.min(data.marginalLimit1, data.marginalLimit2);
                            const max = Math.max(data.marginalLimit1, data.marginalLimit2);
                            averageMonthlyWages4.value = "4#" + min + "#" + max;
                            const label = averageMonthlyWages4.label.replace(/<span id='wagesSpan4_0'>(.*?)<\/span>/, `<span id='wagesSpan4_0'>${min}</span>`)
                            averageMonthlyWages4.label = label.replace(/<span id='wagesSpan4_1'>(.*?)<\/span>/, `<span id='wagesSpan4_1'>${max}</span>`)
                        }
                    }
                })
        }
    }
}
