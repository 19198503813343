import {customElement, query} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkSelect} from "@/components/select/select";
import {SbkInput} from "@/components/input/input";
import {SbkCheckboxGroup} from "@/components/checkbox-group/checkbox-group";
import {SbkRadioGroup} from "@/components/radio-group/radio-group";
import {SbkRadio} from "@/components/radio/radio";

@customElement('sbk-wst-employment-data')
export class SbkWstEmploymentData extends LitElement {

@query('#employmentType')
employmentType!: SbkSelect;

@query('#onlyCreationOfFinalThesis')
onlyCreationOfFinalThesis!: SbkRadioGroup;

@query('#weeklyWorkingHours')
weeklyWorkingHours!: SbkInput;

@query('#weeklyWorkingDays')
weeklyWorkingDays!: SbkCheckboxGroup;

protected createRenderRoot() {
        return this
    }

    connectedCallback() {
        super.connectedCallback();
        this.employmentType.addEventListener('select-value-changed', () => {
            this.handleChange();
        });
        this.employmentType.addEventListener('select-value-changed', () => {
            this.handleMinWageCheck();
        });
        this.onlyCreationOfFinalThesis.addEventListener('radio-group-change', () => {
            this.handleChange();
        });
    }

    handleChange() {
        const checkedRadio = this.onlyCreationOfFinalThesis.querySelector(`sbk-radio[checked]`) as SbkRadio;
        const creationOfFinalThesis = checkedRadio ? checkedRadio.value : null;
        if (this.employmentType.selectedOption?.value === 'DIPLOMAND_BACHELOR_MASTER' && creationOfFinalThesis === 'JA') {
            this.weeklyWorkingHours.classList.add('hide');
            this.weeklyWorkingDays.classList.add('hide');
        } else {
            this.weeklyWorkingHours.classList.remove('hide');
            this.weeklyWorkingDays.classList.remove('hide');
        }
    }

    handleMinWageCheck() {
        const employmentTypeValue = this.employmentType.selectedOption?.value;
        const wageCheck: SbkRadioGroup | null = document.querySelector('sbk-radio-group[id*=\'averageMonthlyWages\']')
        switch (employmentTypeValue) {
            case '':
            case undefined:
                // do nothing
                break
            case 'PRAKTIKANT_FREIWILLIG':
            case 'PRAKTIKANT_VORGESCHRIEBEN':
            case 'DIPLOMAND_BACHELOR_MASTER':
                wageCheck?.classList.remove('validate[required,funcCall[mms.Validator.validateMinimumWage]]')
                wageCheck?.classList.add('validate[required]')
                break
            default:
                wageCheck?.classList.remove('validate[required]')
                wageCheck?.classList.add('validate[required,funcCall[mms.Validator.validateMinimumWage]]')
                break
        }
    }
}
