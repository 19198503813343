import {customElement, query, property} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkDatepicker} from "@/components/datepicker/datepicker";
import {SbkAnnotation} from "@/components/annotation/annotation";

@customElement('sbk-wst-lecture-date-input')
export class SbkWstLectureDateInput extends LitElement {

    @query('#lastLectureCurrentSemester')
    lastLectureCurrentSemester!: SbkDatepicker;

    @query('#firstLectureNextSemester')
    firstLectureNextSemester!: SbkDatepicker;

    @query('#hintLectureFreeTime')
    hintLectureFreeTime!: SbkAnnotation;

    @property({ type: Number })
    daterange: number | null = null;

    protected createRenderRoot() {
        return this
    }

    connectedCallback() {
        super.connectedCallback();
        this.lastLectureCurrentSemester.addEventListener('datepicker-change', () => {
            this.handleChange();
        });
        this.firstLectureNextSemester.addEventListener('datepicker-change', () => {
            this.handleChange();
        });
    }

    handleChange() {
        if (this.daterange && this.lastLectureCurrentSemester.datepickerValue && this.firstLectureNextSemester.datepickerValue) {
            const [lastLectureCurrentSemesterDay, lastLectureCurrentSemesterMonth, lastLectureCurrentSemesterYear] = this.lastLectureCurrentSemester.datepickerValue.split('.').map((value) => parseInt(value, 10));
            const fromDate = new Date(lastLectureCurrentSemesterYear, lastLectureCurrentSemesterMonth - 1, lastLectureCurrentSemesterDay);
            const maxToDate = new Date(fromDate.getTime() + this.daterange);

            const [firstLectureNextSemesterDay, firstLectureNextSemesterMonth, firstLectureNextSemesterYear] = this.firstLectureNextSemester.datepickerValue.split('.').map((value) => parseInt(value, 10));
            const selectedDate = new Date(firstLectureNextSemesterYear, firstLectureNextSemesterMonth - 1, firstLectureNextSemesterDay);
            if (selectedDate > maxToDate) {
                this.hintLectureFreeTime.classList.remove('hide');
            } else {
                this.hintLectureFreeTime.classList.add('hide');
            }
        }
    }
}
