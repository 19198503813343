import {customElement, query} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkInput} from "@/components/input/input";
import {SbkAnnotation} from "@/components/annotation/annotation";

@customElement('sbk-wst-compare-employment-with-study')
export class SbkWstCompareEmploymentToWithEndOfStudy extends LitElement {

    @query('#expectedEndOfStudy')
    expectedEndOfStudy!: SbkInput;

    @query('#storedEmploymentTo')
    storedEmploymentTo!: HTMLInputElement;

    @query('#hintExpectedEndOfStudy')
    hintExpectedEndOfStudy!: SbkAnnotation;

    protected createRenderRoot() {
        return this
    }

    connectedCallback() {
        super.connectedCallback();
        this.expectedEndOfStudy.addEventListener('input-change', () => {
            this.handleChange();
        });
    }

    handleChange() {
        if (this.storedEmploymentTo.value) {
            const [expectedEndOfStudyMonth, expectedEndOfStudyYear] = this.expectedEndOfStudy.value.split('.').map((value) => parseInt(value, 10));
            // using index 0 and the actual month as monthIndex results in the last day of the month!
            const expectedEndOfStudyDate = new Date(expectedEndOfStudyYear, expectedEndOfStudyMonth, 0);

            const [storedEmploymentToYear, storedEmploymentToMonth, storedEmploymentToDay] = this.storedEmploymentTo.value.split('-').map((value) => parseInt(value, 10));
            const storedEmploymentToDate = new Date(storedEmploymentToYear, storedEmploymentToMonth - 1, storedEmploymentToDay);
            if (storedEmploymentToDate > expectedEndOfStudyDate) {
                this.hintExpectedEndOfStudy.classList.remove('hide');
            } else {
                this.hintExpectedEndOfStudy.classList.add('hide');
            }
        }
    }
}
