import {customElement} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkLabelMixin} from "@/components/label/label-mixin";
import {labelStyles} from "@/components/label/label.styles";

/**
 * Label element.
 *
 */
@customElement('sbk-label')
export class SbkFormControlDependency extends SbkLabelMixin(LitElement) {

    static get styles() {
        return [
            labelStyles,
        ];
    }

    render() {
        return this.labelTemplate('label')
    }
}
